@import url('../../node_modules/bootstrap/dist/css/bootstrap.css');
@import url('./components/nav/nav.css');
@import url('./components/hero/hero.css');

[data-inviewport="slide-up"] {
	transition: 1s;
	opacity: 0;
	transform: translateY(50px);
}

[data-inviewport="slide-up"].is-inViewport {
	opacity: 1;
	transform: translateY(0px);
}

[data-inviewport="scale-in"] {
	transition: 2s;
	transform: scale(0.1);
}

[data-inviewport="scale-in"].is-inViewport {
	transform: scale(1);
}

[data-inviewport="fade-rotate"] {
	transition: 2s;
	opacity: 0;
}

[data-inviewport="fade-rotate"].is-inViewport {
	transform: rotate(180deg);
	opacity: 1;
}


.animate_delay-1.is-inViewport {
	transition-delay: 200ms;
}

.animate_delay-2.is-inViewport {
	transition-delay: 400ms;
}

.animate_delay-3.is-inViewport {
	transition-delay: 600ms;
}

.animate_delay-4.is-inViewport {
	transition-delay: 800ms;
}

.animate_delay-5.is-inViewport {
	transition-delay: 1000ms;
}

.animate_delay-6.is-inViewport {
	transition-delay: 1200ms;
}

.animate_delay-500ms.is-inViewport {
	transition-delay: 500ms;
}

.animate_delay-1000ms.is-inViewport {
	transition-delay: 1000ms;
}

.animate_delay-1500ms.is-inViewport {
	transition-delay: 1500ms;
}

.animate_delay-2000ms.is-inViewport {
	transition-delay: 2000ms;
}

.animate_delay-2500ms.is-inViewport {
	transition-delay: 2500ms;
}

img {
	max-width: 100%;
}

.section-pad {
	padding-top: 60px;
	padding-bottom: 60px;
}

.featured-item {
	padding-top: 60px;
	padding-bottom: 60px;
}

.featured-item:hover img {
	width: 60px;
	height: auto;
}

.swappable {
	position: relative;
}

.swappable .swap {
	display: none;
}

.swappable .swap-hide {
	display: block;
}

.featured-item img {
	height: 120px;
}

.swappable:hover .swap {
	display: block;
}

.swappable:hover .swap-hide {
	display: none;
}

.swappable-overlay:hover .swap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}