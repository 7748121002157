.hero {
	font-family: var(--hero-font-family);
	position: relative;
	background-color: black;
	min-height: 55vh;
	width: 100%;
	overflow: hidden;
}

.hero .background {
	position: absolute;
	top: 0 ;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	display: flex;
}
.hero .background img {
	display: block;
	width: 100vw;
	height: 100vh;
	object-fit: cover; /* or object-fit: contain; */
}
.hero video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: 0;
	-ms-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}
.hero .container,
.hero .container-fluid {
	position: relative;
	z-index: 2;
	height: 100%;
	display: flex;
	align-items: end;
	padding: 4rem 2rem;
}
.hero .container-fluid {
	padding: 1rem;
}
@media screen and (min-width: 768px) {
	.hero .container-fluid {
		padding: 4rem;
	}
}
.hero .hero-center {
	align-items: center;
}
.hero .overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: black;
	opacity: 0.5;
	z-index: 1;
}
.hero h1 {
	font-family: var(--hero-heading-font-family);
	font-weight: var(--hero-heading-font-weight);
}
.hero p {
	font-family: var(--hero-font-family);
	font-weight: var(--hero-font-weight);
	line-height: var(--hero-line-height);
}

@media (min-width: 768px ) {
	.hero h1 {
		font-size: 5rem;
	}
}